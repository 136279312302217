<template>
  <div>
  </div>
</template>

<script>
export default {
  data: () => ({
    title: "Ini halaman Dashboard",
  }),
  watch: {},
  computed: {},
  created() {},
  mounted(){
    
  },
  methods: {},
}
</script>